import React from 'react';
import Form from "./Form";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {
    render() {
        return (<Form/>)
    };
}

export default App;