import React, {useEffect, useState} from 'react';
import FormProducts from './FormProducts';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Form() {
    const [form, setForm] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState([]);
    const [formIsReady, setFormIsReady] = useState(false);
    const API_URL = process.env.REACT_APP_MARKETPLACE_API_URL;

    const showToast = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const getFormByOrderId = (orderId, formId) => {
        fetch(`${API_URL}/order-forms/${orderId}?formId=${formId}`)
            .then((res) => res.json())
            .then((form) => setForm(form))
            .then(() => setIsLoading(false))
            .catch(rejected => {
                setIsLoading(false)
                console.log(rejected);
            });
    }

    useEffect(() => {
        let formIsCompleted = true;

        if(state.length < 1) {
            formIsCompleted = false;
        }

        form?.products?.map((product) => {
            let selectedQuantity = calculateSum(state.filter((userFormProduct) => userFormProduct.offer_id = product.offer_id), 'value');
            if (product.quantity !== selectedQuantity) {
                formIsCompleted = false;
            }
        });

        setFormIsReady(formIsCompleted);
    }, [state]);

    const handleChange = (e) => {
        const offerId = e.target.getAttribute('data-offer_id');
        const maxQuantity = form.products.find((product) => product.offer_id = offerId).quantity ?? 0;
        const selectedProducts = document.querySelectorAll(`[data-offer_id="${offerId}"]`);

        let currentQuantity = 0;

        for (let i = 0; i < selectedProducts.length; i++) {
            currentQuantity += parseInt(selectedProducts[i].value ?? '0');
        }

        if (e.target.value < 0) {
            e.target.value = '';
        }

        if (currentQuantity > maxQuantity) {
            showToast(`Przekroczono ilość zakupionych sztuk! Maksymalna ilość to ${maxQuantity}!`);
            e.target.value = '';
        }

        const userForm = [...state];

        const index = e.target.name;

        let value = parseInt(e.target.value);

        if (isNaN(value)) {
            value = 0;
        }

        const field = userForm.findIndex((obj => obj.index === index))

        if (field !== -1) {
            userForm[field].value = value;
            setState(userForm);
        } else {
            setState((prevState) => [
                ...prevState,
                {
                    index: index,
                    offer_id: offerId,
                    value: value
                }
            ]);
        }
    }

    const calculateSum = (array, property) => {
        return array.reduce((accumulator, object) => {
            return accumulator + object[property];
        }, 0);
    }

    const sendForm = (event) => {
        event.preventDefault();

        if (!formIsReady) {
            return showToast(`Wymagane ilości są nieprawidłowe!`);
        }

        const requestOptions = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "PATCH",
            _method: "PATCH",
            body: JSON.stringify({
                formId: form.purchase_form.id,
                data: state.filter((product) => product.value > 0)
            })
        };

        fetch(`${API_URL}/order-forms/${orderId}`, requestOptions)
            .then((res) => res.json())
            .then((form) => setForm(form))
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const orderId = queryParameters.get("orderId")
        const formId = queryParameters.get("formId")

        setOrderId(orderId)
        getFormByOrderId(orderId, formId);
    }, [])

    return (<>
        {!isLoading && (
            <div className="maincontainer">
                <ToastContainer/>
                <div
                    className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
                    <h5 className="my-0 mr-md-auto font-weight-normal">Eurofirany</h5>
                </div>
                <div className="container">
                    {form?.order_id
                        ? <>
                            {
                                !form.completed_at
                                    ? <>
                                        <div className="py-5 text-center">
                                            <h2>{form.purchase_form.title}</h2>
                                            <p className="lead"
                                               dangerouslySetInnerHTML={{__html: form.purchase_form.description}}></p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 order-md-1">
                                                <h4 className="mb-3">Formularz do zamówienia Allegro Eurofirany
                                                    - {form.order_id}</h4>
                                                <form className="needs-validation" onSubmit={sendForm}
                                                      onKeyDown={(e) => e.key === 'Enter' ? e.preventDefault() : null}>
                                                    <div className="row">
                                                        <div className="mb-3">
                                                            <FormProducts products={form.products}
                                                                          purchaseForm={form.purchase_form}
                                                                          handleChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr className="mb-4"/>
                                                    <button className="btn btn-warning btn-md btn-block" type="submit"
                                                            style={{cursor: !formIsReady ? 'not-allowed' : 'pointer', pointerEvents: 'auto'}}
                                                            disabled={!formIsReady}>
                                                        Zatwierdź
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </>
                                    : <div className="alert alert-success text-center" role="alert">
                                        <strong>Dziękujemy!</strong> Formularz dla zamówienia "{orderId}" został prawidłowo
                                        wypełniony!
                                    </div>
                            }
                        </>
                        : <>
                            <div className="alert alert-warning text-center" role="alert">
                                Formularz dla zamówienia "{orderId}" nie został znaleziony!
                            </div>
                        </>
                    }
                    <footer className="my-5 pt-5 text-muted text-center text-small">
                        <p className="mb-1">© {new Date().getFullYear()} Eurofirany B.B. Choczyńscy Sp.J. Wszystkie
                            prawa
                            zastrzeżone.</p>
                    </footer>
                </div>
            </div>
        )}
    </>)
}

export default Form