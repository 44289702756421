import React from 'react';
import FormProduct from "./FormProduct";

function FormProducts({products, purchaseForm, handleChange}) {
    return (<>
        {products.map(
            product => (<>
                <hr />
                <h5><a href={`https://allegro.pl/oferta/${product.offer_id}`} rel="noreferrer" target="_blank">{product.name}</a></h5>
                <p>Liczba zakupionych produktów: {product.quantity}</p>
                <p>Wartość zakupionych produktów: {(product.quantity * product.price).toFixed(2)} zł</p>
                <table className="table">
                    <thead className="thead-dark">
                    <th>Miniatura</th>
                    <th>Kolor</th>
                    <th>Symbol</th>
                    <th>Ilość</th>
                    </thead>
                    <tbody>
                    <FormProduct product={product}
                                 purchaseForm={purchaseForm}
                                 handleChange={handleChange}
                    />
                    </tbody>
                </table>
            </>)
        )
        }
    </>)
}

export default FormProducts