import React from "react";

function FormProduct({product, purchaseForm, handleChange}) {
    return (<>
        {
            purchaseForm.purchase_form_fields.map(formField => (
                <tr>
                    <td><img
                        src={formField.image ?? 'https://efpim.eurofirany.com.pl/bundles/pimcoreadmin/img/filetype-not-supported.svg'}
                        width='100'/></td>
                    <td>{formField.name}</td>
                    <td>{formField.sku}</td>
                    <td><input data-offer_id={product.offer_id}
                               name={formField.sku}
                               type='number'
                               defaultValue={0}
                               min='0'
                               onFocus={(e) => e.target.value == 0 ? e.target.value = "" : null}
                               onBlur={(e) => e.target.value.trim().length === 0 ? e.target.value = 0 : null}
                               onKeyUp={(e) => e.which === 13 ? e.target.blur() : null}
                               max={product.quantity}
                               onChange={handleChange}/>
                    </td>
                </tr>
            ))
        }
    </>)
}

export default FormProduct